<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('system_menu.detailDialog') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        :form-field="formField"
        label-width="90px"
        :grid="{span: 24}"
        :view-flag="viewFlag"
      />
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
const BASEURL = {
  get: '/system/menu/get',
  save: '/system/menu/save',
  update: '/system/menu/update'
}
export default {
  name: 'SystemMenuDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      formModel: { parent: {}, type: 'menu', status: 'Y' }
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },

    menuType () { return this.$getDictList('menu_type') },

    formField () {
      return [
        // {
        //   prop: 'parent.title',
        //   label: this.$t('system_menu.parentTitle'),
        //   type: 'Input',
        //   viewFlag: true
        // },
        {
          prop: 'title',
          label: this.$t('system_menu.title'),
          type: 'Input',
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'name',
          label: this.$t('system_menu.name'),
          type: 'Input',
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'permission',
          label: this.$t('system_menu.permission'),
          type: 'Input',
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: this.formModel.type === 'button' }
        },
        {
          prop: 'sort',
          label: this.$t('system_menu.sort'),
          type: 'NumberInput',
          component: { min: 0 }
        },
        {
          prop: 'icon',
          label: this.$t('system_menu.icon'),
          type: 'Input'
          // rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: Number(this.formModel.isShow) === 1 }
        },
        {
          prop: 'type',
          label: this.$t('system_menu.isShow'),
          type: 'RadioGroup',
          component: { optionList: this.menuType }
        },
        {
          prop: 'status',
          label: this.$t('system_menu.status'),
          type: 'Select',
          component: { optionList: this.$getDictList('data_status') }
        }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },

    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          if (this.detailData.id) {
            // this.formModel.parent = { id: this.formModel.parentId }
            this.$axios
              .post(BASEURL.update, this.formModel)
              .then(resp => {
                this.currentShowFlag = false
                this.handleDataSearch()
              })
              .finally(() => {
                loadingFlag.close()
              })
          } else {
            this.$axios
              .post(BASEURL.save, this.formModel)
              .then(resp => {
                this.currentShowFlag = false
                this.handleDataSearch()
              })
              .finally(() => {
                loadingFlag.close()
              })
          }
        }
      })
    },

    handleDialogOpen () {
      if (!this.detailData.id) {
        Object.assign(this.formModel, this.detailData)
        return
      }
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })

      this.$axios
        .post(BASEURL.get, { id: this.detailData.id })
        .then(resp => {
          const respData = resp.data
          this.formModel = respData
        })
        .finally(() => {
          loadingFlag.close()
        })
    },

    handleDialogClosed () {
      this.formModel = { parent: {}, type: 'menu' }
      this.$refs.form.resetFields()
    }
  }
}
</script>
